var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"program-item"},[_c('div',{staticClass:"program-wrap"},[_c('div',{staticClass:"program-background"}),_c('div',{staticClass:"poster",style:({
                'background-image': 'url("' + _vm.item.image + '")',
            }),attrs:{"role":"image","alt":_vm.item.title}},[(_vm.behavior === 'disallow' && _vm.item.type !== 'group')?_c('md-icon',{staticClass:"md-size-2x subscriber-lock",attrs:{"title":_vm.computedSubscribeText}},[_vm._v("lock")]):_vm._e(),(_vm.behavior === 'disallow' && _vm.item.type !== 'group')?_c('router-link',{staticClass:"overlay",attrs:{"title":_vm.computedSubscribeText,"to":"/subscribe"}},[_c('div',{staticClass:"playbutton"},[_c('md-icon',{staticClass:"md-size-2x subscriber-lock-button"},[_vm._v("lock")])],1)]):_c('router-link',{staticClass:"overlay",attrs:{"to":_vm.link,"title":_vm.item.type === 'video'
                        ? 'Watch Now'
                        : _vm.item.type === 'audio'
                          ? 'Listen'
                          : 'Explore'}},[_c('div',{staticClass:"playbutton"},[(_vm.item.type !== 'group')?_c('svgicon',{attrs:{"name":"gideo_play","width":"4rem","height":"4rem","color":"#ffffff"}}):_c('svgicon',{attrs:{"name":"arrow_forward","width":"4rem","height":"4rem","color":"#ffffff"}})],1)])],1),(_vm.item.displayPosterTitle)?_c('h3',{staticClass:"title",attrs:{"title":_vm.item.title}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e(),_c('div',{staticClass:"description"},[(_vm.item.displayPosterTitle)?_c('h4',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.item.subtitle || ' ')+" ")]):_vm._e(),(_vm.item.type !== 'group')?_c('div',{staticClass:"video-info"},[(_vm.showViews)?_c('h6',{staticClass:"video-views video-info-item"},[_vm._v(" "+_vm._s(_vm.formattedViews)+" views ")]):_vm._e(),(_vm.showViews && _vm.showDate)?_c('span',{staticClass:"dot"},[_vm._v("•")]):_vm._e(),(_vm.showDate)?_c('h6',{staticClass:"video-date video-info-item"},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")]):_vm._e(),(
                        _vm.showViews &&
                        _vm.showDate &&
                        !_vm.item.scheduleId &&
                        !_vm.item.live
                    )?_c('span',{staticClass:"dot"},[_vm._v("•")]):_vm._e(),(!_vm.item.scheduleId && !_vm.item.live)?_c('h6',{staticClass:"time video-info-item"},[_vm._v(" "+_vm._s(_vm.formattedTime)+" ")]):_vm._e()]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.item.webDescription)}}),_c('router-link',{staticClass:"playbutton small-btn",attrs:{"to":_vm.link,"title":"Go to Details"}},[_c('svgicon',{attrs:{"name":"arrow_forward","width":"3rem","height":"3rem","color":"#ffffff"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }