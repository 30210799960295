import devConfig from '@/devconfig.js';
import devPages from '@/devpages.js';
//import Vue from 'vue';
import axios from 'axios';
//import ms from 'ms';
//import moment from 'moment';
import merge from 'lodash/merge';
import assign from 'lodash/assign';
import filter from 'lodash/filter';
import P from 'bluebird';
import xmlParser from '@/services/xmlParser.js';
import { getSegmentHandlers } from '@/services/segment.js';

const defaultBaseUrl = 'https://ott.gideo.video/api/legacy';
const settingsBaseURL = devConfig?.settingsBaseURL || defaultBaseUrl;
const domain = devConfig?.domain || global.location.host;

function getAPIError(e, defaultMessage, handlers = {}) {
    defaultMessage = defaultMessage || 'An unknown error occurred';

    const errorMessage =
        e.errorMessage || e.response?.data?.error || defaultMessage;
    const userMessage =
        e.userMessage || e.response?.data?.message || defaultMessage;

    const status = e.status || e.response?.status;

    const hError =
        typeof handlers[status] === 'function'
            ? handlers[status](e.response?.data, e)
            : undefined;

    const errorData = {
        code:
            e.code ||
            (status === 503
                ? 'SERVICE_UNAVAILABLE'
                : status >= 500
                  ? 'SERVER_ERROR'
                  : 'APIERROR'),
        response: e.response,
        data: e.data || e.response?.data,
        status,
        userMessage,
        errorMessage,
    };

    return assign(new Error(errorMessage), errorData, hError);
}

function getToken() {
    return localStorage.getItem('gideo-token');
}

function setToken(t) {
    localStorage.setItem('gideo-token', t);
}

function setUser(u) {
    localStorage.setItem('gideo-user', JSON.stringify(u));
}

function getUser() {
    let user = localStorage.getItem('gideo-user');
    return JSON.parse(user) || {};
}

function logout(reason, bus) {
    localStorage.removeItem('gideo-token');
    localStorage.removeItem('gideo-user');
    bus.$emit('logged-out', { reason });
}

function getRequestFunction(base, bus) {
    return async function (uri, options, asyncTransformer) {
        const token = getToken();

        options = options || {};
        options.base = options.base || base;
        options.base = await options.base;

        const request = merge(
            {
                url: `${options.base}${uri}`,

                headers:
                    token === null || !options.sendToken
                        ? undefined
                        : {
                              authorization: `Bearer ${token}`,
                          },
            },
            options,
        );

        return P.resolve(
            axios.request(request).then((response) => {
                if (typeof asyncTransformer === 'function') {
                    return asyncTransformer(response.data).then(
                        (parsedData) => {
                            response.data = parsedData;
                            return response;
                        },
                    );
                }

                return response;
            }),
        )
            .tapCatch((err) => {
                if (err.response && err.response.status === 401) {
                    logout(getToken() !== null ? 'expired' : 'required', bus);
                }
            })
            .catch((err) => {
                // If the server provides an error message in the payload,
                // construct a new error using that as the text for
                // convenience.
                const errorText = err.response?.data?.error;

                return P.reject(
                    errorText
                        ? assign(new Error(errorText), {
                              response: err.response,
                          })
                        : err,
                );
            });
    };
}

async function getSettings() {
    return getRequestFunction(settingsBaseURL)(
        `?cmd=getSettings&domain=${domain}`,
        { headers: { accept: 'application/json' } },
    );
}

const settings = getSettings().then((data) => {
    const settings = data.data;
    const appConfig = devConfig
        ? merge({}, settings.appConfig?.webtv || {}, devConfig)
        : settings.appConfig?.webtv;

    appConfig.assetsURL = appConfig.assetsURL || '/assets/';

    function prefixUrl(url, prefix) {
        return /^(https?:\/\/|data:image|\/)/i.test(url)
            ? url
            : `${prefix}${url}`;
    }

    function adjustUrl(key, def) {
        const value = appConfig[key];

        if (!value) {
            appConfig[key] = undefined;
        } else {
            appConfig[key] = prefixUrl(
                value === true ? def : value,
                appConfig.assetsURL,
            );
        }
    }

    adjustUrl('backgroundImage', 'background.png');
    adjustUrl('cssURL', 'main.css');

    //white label logo
    if (!appConfig.whiteLabelLogo) {
        appConfig.whiteLabelLogo = [
            {
                url: 'https://gideo.video',
                image: require('@/assets/powered-by-gideo.png'),
            },
        ];
    }
    if (!Array.isArray(appConfig.whiteLabelLogo)) {
        appConfig.whiteLabelLogo = [appConfig.whiteLabelLogo];
    }

    appConfig.whiteLabelLogo.forEach((wll) => {
        if (!wll.image) {
            wll.image = require('@/assets/powered-by-gideo.png');
            wll.url = 'https://gideo.video';
        } else {
            wll.image = prefixUrl(wll.image, appConfig.assetsURL);
        }
    });

    appConfig.logo = prefixUrl(
        appConfig.logo || 'logo.png',
        appConfig.assetsURL,
    );

    merge(settings, appConfig);
    delete settings.appConfig;

    settings.vastUrl =
        typeof settings.vastUrl === 'string'
            ? settings.vastUrl
            : settings.vastUrl?.webtv;

    global.webtvConfig = settings;
    return settings;
});
const accountId = settings.then((v) => v.accountId);
const $segment = settings.then((v) => getSegmentHandlers(v.accountId));
const $products = {};

// apiBaseUrls properties are possibly overridden when gideo.getSettings is called
const apiBaseUrls = {
    ott: settings.then((s) => s.apiBaseUrls.ott || defaultBaseUrl),
    auth: settings.then((s) => s.apiBaseUrls.auth || defaultBaseUrl),
    store: settings.then((s) => s.apiBaseUrls.store || defaultBaseUrl),
    analytics: settings.then((s) => s.apiBaseUrls.analytics || ''),
};

async function getSegment() {
    try {
        return $segment;
    } catch (e) {
        //eslint-disable-next-line
        console.error(e);
    }
}
async function onLogin(self) {
    const segment = await getSegment();
    let user = getUser();

    if (!user?.username) {
        user = await self.getUserInfo(true);
    }
    typeof segment?.onAuthenticate === 'function' &&
        segment.onAuthenticate(user);
    self.bus.$emit('logged-in');
}

async function onPurchase(self, productId) {
    await self.login();
    const user = getUser();
    self.bus.$emit('subscribed');
    const segment = await getSegment();
    typeof segment?.onPurchase === 'function' &&
        segment.onPurchase(user, $products[productId]);
}

export default {
    install(Vue, { base } = { base: apiBaseUrls.ott }) {
        const bus = new Vue();
        const request = getRequestFunction(base, bus);

        const self = {
            bus,

            get authed() {
                return getToken() !== null;
            },

            get subscriber() {
                let user = getUser();
                return !!user?.subscriber;
            },

            async getPageContent(id) {
                if (process.env.NODE_ENV === 'development') {
                    if (devPages[id]) {
                        return devPages[id];
                    }
                }
                return (
                    await axios.get(`/rawpage/${id}.html`, {
                        responseType: 'text',
                    })
                ).data;
            },

            async login(o) {
                let aId = await accountId;
                o = merge(o, {
                    cmd: 'authenticate',
                    accountId: aId,
                    platform: 'web',
                });

                return request('', {
                    method: 'post',
                    data: o,
                    base: apiBaseUrls.auth,
                    sendToken: true,
                })
                    .then(async ({ data }) => {
                        setToken(data.deviceToken);
                        setUser({
                            id: data.userId,
                            subscriber: data.subscriber,
                        });
                        await onLogin(self);
                    })
                    .catch((e) => {
                        let status = e.response?.status;

                        if (status === 401) {
                            return P.reject(
                                Object.assign(
                                    new Error('Invalid Credentials'),
                                    { code: 'EKEYREJECTED' },
                                ),
                            );
                        } else {
                            return P.reject(
                                Object.assign(new Error('Unknown Error'), {
                                    code: '',
                                    error: e,
                                }),
                            );
                        }
                    });
            },

            logout() {
                return P.try((reason) => logout(reason, this.bus));
            },

            async resetPassword(o) {
                const aId = await accountId;
                o = merge(o, { cmd: 'reset_password', accountId: aId });

                return request('', {
                    method: 'post',
                    data: o,
                    base: apiBaseUrls.auth,
                    sendToken: true,
                }).catch((e) => {
                    return P.reject(
                        Object.assign(new Error('Unknown Error'), {
                            code: '',
                            error: e,
                        }),
                    );
                });
            },

            async deleteUserAccount(o) {
                const aId = await accountId;
                const data = {
                    cmd: 'deleteUserAccount',
                    accountId: aId,
                    password: o.password,
                };
                if (o.username) {
                    data.username = o.username;
                }

                const wasAuthed = this.authed;
                await request('', {
                    method: 'delete',
                    data,
                    base: apiBaseUrls.auth,
                    sendToken: true,
                }).catch((e) => {
                    throw getAPIError(
                        e,
                        'An unknown error occurred while trying to delete user data.',
                        {
                            401: () => ({
                                message: 'Invalid Credentials',
                                code: 'EKEYREJECTED',
                                userMessage: wasAuthed
                                    ? 'Password does not match our records. You have now been logged out!'
                                    : 'Incorrect Username and/or Password.',
                            }),
                        },
                    );
                });

                this.logout();
            },

            async getSuggestedContent(id) {
                return this.getProgramGroups(id, true);
            },

            async getProgramGroups(id, isSuggestedContent) {
                if (!id) {
                    id = '';
                }
                let user = getUser();
                let aId = await accountId;

                return request(
                    `?cmd=getCategoryChildren&AccountID=${aId}&CategoryID=${id}`,
                    {
                        sendToken: !!isSuggestedContent,
                    },
                    (data) =>
                        xmlParser.parseProgramItems(data).then((data) => {
                            //remove all items hidden for this user
                            const behavior = user?.subscriber
                                ? 'subscriberBehavior'
                                : 'freeBehavior';
                            return filter(
                                data,
                                (item) => item[behavior] !== 'hide',
                            );
                        }),
                );
            },

            async getProgramGroup(id) {
                if (!id) {
                    id = '';
                }
                let user = getUser();
                let aId = await accountId;

                return request(
                    `?cmd=getCategory&AccountID=${aId}&CategoryID=${id}`,
                    {},
                    (data) =>
                        xmlParser.parseProgramItems(data).then((parsedData) => {
                            const behavior = user?.subscriber
                                ? 'subscriberBehavior'
                                : 'freeBehavior';

                            parsedData = filter(
                                parsedData,
                                (item) => item[behavior] !== 'hide',
                            );

                            return parsedData && parsedData[0];
                        }),
                );
            },

            async getProgram(id) {
                if (!id) {
                    id = '';
                }
                let user = getUser();
                let aId = await accountId;

                return request(
                    `?cmd=getVideo&AccountID=${aId}&VideoID=${id}`,
                    {},
                    (data) =>
                        xmlParser.parseProgramItems(data).then((parsedData) => {
                            const behavior = user?.subscriber
                                ? 'subscriberBehavior'
                                : 'freeBehavior';
                            parsedData = filter(
                                parsedData,
                                (item) => item[behavior] !== 'hide',
                            );
                            return parsedData && parsedData[0];
                        }),
                );
            },

            async getProgramURLs(id) {
                if (!id) {
                    id = '';
                }
                let aId = await accountId;

                return request(
                    `?cmd=getVideoUrls&accountId=${aId}&videoId=${id}`,
                    {
                        sendToken: true,
                    },
                );
            },

            async createPlaybackSession(videoId) {
                try {
                    const data = {
                        cmd: 'createPlaybackSession',
                        videoId,
                    };
                    const res = await request('', {
                        base: apiBaseUrls.ott,
                        method: 'post',
                        data,
                        sendToken: true,
                    });
                    if (res.status === 204) {
                        this.logout();
                        throw getAPIError(
                            {
                                status: 401,
                                response: res,
                                code: 'EKEYREJECTED',
                            },
                            'You must login to watch this video.',
                        );
                    }

                    if (!res.data?.id) {
                        throw getAPIError(
                            {
                                status: 500,
                                response: res,
                                code: 'SERVER_ERROR',
                            },
                            'Failed to retrieve playback session id',
                        );
                    }

                    return res.data.id;
                } catch (err) {
                    throw getAPIError(
                        err,
                        'An unknown error occurred while trying to create a playback session.',
                    );
                }
            },

            async checkPlaybackSession(sessionId) {
                try {
                    await request(
                        `?cmd=checkPlaybackSession&sessionId=${sessionId}`,
                        {
                            base: apiBaseUrls.ott,
                            method: 'get',
                        },
                    );

                    return true;
                } catch (err) {
                    if (err.response?.status >= 500) {
                        //allow playback to continue uninterrupted
                        return true;
                    }
                    throw getAPIError(
                        err,
                        'An unknown error occurred while trying to create a playback session.',
                    );
                }
            },

            async deletePlaybackSession(sessionId) {
                try {
                    const data = {
                        cmd: 'deletePlaybackSession',
                        sessionId,
                    };
                    await request('', {
                        base: apiBaseUrls.ott,
                        method: 'post',
                        data,
                        sendToken: true,
                    });
                } catch (err) {
                    //this should not interfere with the operation of the app
                    return;
                }
            },

            async getNowPlaying(id) {
                if (!id) {
                    id = '';
                }
                let aId = await accountId;

                return request(`?cmd=nowPlaying&accountId=${aId}&videoId=${id}`)
                    .then((data) => data.data)
                    .catch((e) => {
                        //eslint-disable-next-line
                        console.error(e);
                        return undefined;
                    });
            },

            async search(term) {
                let user = getUser();
                let aId = await accountId;

                return request(
                    `?cmd=getVideos&AccountID=${aId}&Search=${term}`,
                    {},
                    (data) =>
                        xmlParser.parseProgramItems(data).then((data) => {
                            //remove all items hidden for this user
                            const behavior = user?.subscriber
                                ? 'subscriberBehavior'
                                : 'freeBehavior';
                            return filter(
                                data,
                                (item) => item[behavior] !== 'hide',
                            );
                        }),
                );
            },

            async getTermsOfUse() {
                let aId = await accountId;

                return request(
                    `?cmd=getTermsOfUse&AccountID=${aId}`,
                    {},
                    (data) => xmlParser.parseTerms(data),
                );
            },
            async getPrivacyPolicy() {
                let aId = await accountId;

                return request(
                    `?cmd=getPrivacyPolicy&AccountID=${aId}`,
                    {},
                    (data) => xmlParser.parseTerms(data),
                );
            },
            async getUserInfo(force = false) {
                const user = getUser();
                if (user?.username && !force) {
                    return user;
                }
                if (!getToken()) {
                    return undefined;
                }
                return request('?cmd=getUser', {
                    base: apiBaseUrls.auth,
                    sendToken: true,
                })
                    .then((user) => {
                        user = user.data;
                        setUser(user);
                        self.bus.$emit('userUpdated', user);
                        return user;
                    })
                    .catch(() => {
                        return undefined;
                    });
            },
            async getUserSubscriptions() {
                return (await this.getUserInfo())?.subscriptions;
            },
            async checkGiftExists(code) {
                let aId = await accountId;

                let data = {
                    cmd: 'redeem',
                    platform: 'web',
                    accountId: aId,
                    code,
                    trial_run: true,
                };

                return request('', {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then(() => true)
                    .catch((e) => {
                        let status = e?.response?.status;
                        if (status === 404) {
                            return false;
                        }
                        return true;
                    });
            },
            async redeemGift(code, creds = {}) {
                const aId = await accountId;

                const { email, password } = creds;
                const data = {
                    cmd: 'redeem',
                    platform: 'web',
                    accountId: aId,
                    email,
                    password,
                    code,
                };

                return request('', {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then(async ({ data }) => {
                        const wasAuthed = this.authed;
                        setToken(data.token);
                        if (!wasAuthed) {
                            await onLogin(self);
                        } else {
                            await this.getUserInfo(true);
                        }
                        self.bus.$emit('subscribed');
                    })
                    .catch((e) => {
                        throw getAPIError(
                            e,
                            'An unknown error occurred while trying to redeem a gift.',
                        );
                    });
            },
            async checkSignupFields(email, customAnswers) {
                return this.createUser(
                    email,
                    undefined,
                    customAnswers,
                    undefined,
                    true,
                );
            },
            async createUser(
                email,
                password,
                customAnswers,
                captcha,
                trial_run = false,
            ) {
                let aId = await accountId;

                let data = {
                    cmd: 'signup',
                    source: 'web',
                    accountId: aId,
                    captcha,
                    email,
                    password,
                    customAnswers,
                    trial_run,
                };

                return request('', {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                })
                    .then(async ({ data }) => {
                        if (trial_run) {
                            return true;
                        }
                        setToken(data.token);
                        await onLogin(self);
                    })
                    .catch((e) => {
                        const errorText =
                            e.response?.data?.error ||
                            'An unknown error occurred while trying to create a new user account';

                        return P.reject(
                            errorText
                                ? assign(new Error(errorText), {
                                      response: e.response,
                                      userMessage:
                                          e.response?.data?.message ||
                                          'An unknown error occurred. Please try again later.',
                                      field: e.response?.data?.field,
                                  })
                                : e,
                        );
                    });
            },
            //this is for payment gateways that don't support webhooks such as EZIC
            async confirmPayment(
                gateway,
                id,
                { captcha, paymentMethod, clientSecret } = {},
            ) {
                try {
                    const data = {
                        paymentMethod,
                        captcha,
                        clientSecret,
                    };
                    return (
                        await request(
                            `/${gateway}/payment_intents/${id}/confirm`,
                            {
                                base: apiBaseUrls.store,
                                method: 'post',
                                data,
                                sendToken: true,
                            },
                        )
                    ).data;
                } catch (err) {
                    throw getAPIError(
                        err,
                        'An unknown error occurred while trying to confirm a payment.',
                    );
                }
            },
            //this is for payment gateways that don't support webhooks such as EZIC
            async confirmSetup(
                gateway,
                id,
                { captcha, paymentMethod, clientSecret } = {},
            ) {
                try {
                    const data = {
                        paymentMethod,
                        captcha,
                        clientSecret,
                    };
                    return (
                        await request(
                            `/${gateway}/setup_intents/${id}/confirm`,
                            {
                                base: apiBaseUrls.store,
                                method: 'post',
                                data,
                                sendToken: true,
                            },
                        )
                    ).data;
                } catch (err) {
                    throw getAPIError(
                        err,
                        'An unknown error occurred while trying to confirm a payment method.',
                    );
                }
            },
            async awaitSetupConfirmation(setupId, retryCount = 0) {
                return request(
                    `/payment-method/${setupId}/await-status-update`,
                    {
                        base: apiBaseUrls.store,
                        method: 'get',
                        sendToken: true,
                    },
                )
                    .then(async (res) => {
                        if (res.data?.error) {
                            return P.reject(
                                Object.assign(new Error(res.data.error), {
                                    response: res,
                                    userMessage: res.data.error,
                                }),
                            );
                        }
                        await this.getUserInfo(true);
                        return res.data?.status;
                    })
                    .catch((e) => {
                        const status = e.response?.status;
                        if (
                            (status === 502 || status === 504) &&
                            retryCount < 5
                        ) {
                            return this.awaitSetupConfirmation(
                                setupId,
                                retryCount + 1,
                            );
                        }
                        if (e.userMessage) {
                            return P.reject(e);
                        }
                        return P.reject(
                            getAPIError(
                                e,
                                'An unknown error occurred while trying to setup a payment method.',
                            ),
                        );
                    });
            },
            async awaitPaymentConfirmation(
                paymentId,
                isDonation = false,
                retryCount = 0,
            ) {
                return request(`/payment/${paymentId}/await-status-update`, {
                    base: apiBaseUrls.store,
                    method: 'get',
                    sendToken: true,
                })
                    .then(async (res) => {
                        if (res.data?.error) {
                            return P.reject(
                                assign(new Error(res.data.error), {
                                    response: res,
                                    userMessage: res.data.error,
                                }),
                            );
                        }
                        if (!isDonation) {
                            onPurchase(self, res.data?.product?.productId);
                        }
                        return res.data.status;
                    })
                    .catch((e) => {
                        const status = e.response?.status;
                        if (
                            (status === 502 || status === 504) &&
                            retryCount < 5
                        ) {
                            return this.awaitPaymentConfirmation(
                                paymentId,
                                isDonation,
                                retryCount + 1,
                            );
                        }
                        return P.reject(
                            getAPIError(
                                e,
                                'An unknown error occurred while trying to make a purchase.',
                            ),
                        );
                    });
            },
            async updatePaymentIntent(
                paymentId,
                productId,
                paymentMethodId,
                captcha,
                purchaseDetails,
            ) {
                const data = {
                    purchase: { productId, ...purchaseDetails },
                    platform: 'web',
                    paymentMethod: paymentMethodId,
                    captcha,
                };

                return request(`/payment/${paymentId}`, {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then((res) => {
                        return res.data;
                    })
                    .catch((e) => {
                        return P.reject(
                            getAPIError(
                                e,
                                'An unknown error occurred while trying to make a purchase.',
                            ),
                        );
                    });
            },
            async updateSetupIntent(setupId, captcha) {
                const data = {
                    platform: 'web',
                    captcha,
                };

                return request(`/payment-method/${setupId}`, {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then((res) => {
                        return res.data;
                    })
                    .catch((e) => {
                        return P.reject(
                            getAPIError(
                                e,
                                'An unknown error occurred while trying to update the setup for a payment method.',
                            ),
                        );
                    });
            },
            async createSetupIntent(captcha) {
                const data = {
                    platform: 'web',
                    captcha,
                };

                return request('/payment-method', {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then((res) => {
                        return res.data;
                    })
                    .catch((e) => {
                        return P.reject(
                            getAPIError(
                                e,
                                'An unknown error occurred while trying to make a set up a payment method.',
                            ),
                        );
                    });
            },
            async createPaymentIntent(
                productId,
                paymentMethodId,
                captcha,
                purchaseDetails,
            ) {
                const data = {
                    purchase: { productId, ...purchaseDetails },
                    platform: 'web',
                    paymentMethod: paymentMethodId,
                    captcha,
                };

                return request('/payment', {
                    base: apiBaseUrls.store,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then((res) => {
                        return res.data;
                    })
                    .catch((e) => {
                        if (e.response?.data?.code === 'payment_pending') {
                            return this.updatePaymentIntent(
                                e.response.data.id,
                                productId,
                                paymentMethodId,
                                captcha,
                                purchaseDetails,
                            );
                        }

                        return P.reject(
                            getAPIError(
                                e,
                                'An unknown error occurred while trying to begin your purchase.',
                            ),
                        );
                    });
            },
            async changePassword(data) {
                data = Object.assign({ cmd: 'change_password' }, data);
                return request('', {
                    base: apiBaseUrls.auth,
                    data,
                    method: 'post',
                }).catch((e) => {
                    return P.reject(
                        getAPIError(
                            e,
                            'An unknown error occurred while trying to update your password.',
                            {
                                401: () => ({
                                    message: 'Token Expired',
                                    code: 'EKEYEXPIRED',
                                    userMessage:
                                        'This password reset token has expired',
                                }),
                            },
                        ),
                    );
                });
            },
            async updateUser(newUser, currentPassword) {
                let data = {
                    cmd: 'update_user',
                    user: newUser,
                };
                if (currentPassword) {
                    data.currentPassword = currentPassword;
                }
                return request('', {
                    base: apiBaseUrls.auth,
                    data,
                    method: 'post',
                    sendToken: true,
                })
                    .then((res) => {
                        const user = getUser() || {};
                        delete newUser.password;
                        setUser(Object.assign(user, newUser));
                        self.bus.$emit('userUpdated', getUser());
                        return res;
                    })
                    .catch((e) => {
                        const errorText =
                            e.response?.data?.message ||
                            e.response?.data?.error ||
                            'An unknown error occurred while trying to update account details.';

                        return P.reject(
                            errorText
                                ? assign(new Error(errorText), {
                                      response: e.response,
                                  })
                                : e,
                        );
                    });
            },
            async upgradeSubscription(transactionId, productId) {
                return request('', {
                    base: apiBaseUrls.store,
                    data: {
                        cmd: 'upgrade',
                        source: 'web',
                        transactionId,
                        productId,
                    },
                    method: 'post',
                    sendToken: true,
                })
                    .then(async (res) => {
                        await this.getUserInfo(true);
                        return res;
                    })
                    .catch((e) => {
                        const errorText =
                            e.response?.data?.error ||
                            'An unknown error occurred while trying to renew subscription.';

                        return P.reject(
                            errorText
                                ? assign(new Error(errorText), {
                                      response: e.response,
                                  })
                                : e,
                        );
                    });
            },
            async renewSubscription(transactionId) {
                return request('', {
                    base: apiBaseUrls.store,
                    data: {
                        cmd: 'renewSubscription',
                        source: 'web',
                        transactionId,
                    },
                    method: 'post',
                    sendToken: true,
                })
                    .then(async (res) => {
                        setToken(res.data.token);
                        await this.getUserInfo(true);
                        return res;
                    })
                    .catch((err) => {
                        return P.reject(
                            getAPIError(
                                err,
                                'An unknown error occurred while trying to renew subscription.',
                            ),
                        );
                    });
            },
            async cancelSubscription(transactionId) {
                return request('', {
                    base: apiBaseUrls.store,
                    data: {
                        cmd: 'cancelSubscription',
                        source: 'web',
                        transactionId,
                    },
                    method: 'post',
                    sendToken: true,
                })
                    .then(async (res) => {
                        await this.getUserInfo(true);
                        return res;
                    })
                    .catch((e) => {
                        const errorText =
                            e.response?.data?.error ||
                            'An unknown error occurred while trying to cancel subscription.';

                        return P.reject(
                            errorText
                                ? assign(new Error(errorText), {
                                      response: e.response,
                                  })
                                : e,
                        );
                    });
            },
            async getSubscriptions(options) {
                options = options || {};
                const aId = await accountId;
                return request(
                    `?cmd=getSubscriptionProducts&accountId=${aId}&platform=web&giftable=${options.giftable}`,
                    {
                        base: apiBaseUrls.store,
                        sendToken: true,
                    },
                ).then((subscriptions) => {
                    subscriptions = subscriptions.data || [];
                    for (const s of subscriptions) {
                        $products[s.productId] = s;
                    }

                    return subscriptions;
                });
            },
            async getProductUpgrades(productId) {
                const aId = await accountId;
                return request(
                    `?cmd=getProductUpgrades&accountId=${aId}&productId=${productId}`,
                    {
                        base: apiBaseUrls.store,
                        sendToken: true,
                    },
                ).then((subscriptions) => subscriptions.data);
            },
        };

        Vue.prototype.$gideo = self;
        //this will load site settings and refresh the user's token
        const refreshToken = () => {
            if (getToken()) {
                return self.login().catch(() => false);
            }
        };
        Vue.prototype.$appSettings = Promise.all([
            settings,
            refreshToken(),
        ]).then(([settings]) => {
            self.bus.$emit('settings-loaded', { settings });
            return settings;
        });
    },
};
