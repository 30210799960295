<template>
    <div class="program-item">
        <div class="program-wrap">
            <div class="program-background" />
            <div
                role="image"
                class="poster"
                :alt="item.title"
                :style="{
                    'background-image': 'url(&quot;' + item.image + '&quot;)',
                }"
            >
                <md-icon
                    v-if="behavior === 'disallow' && item.type !== 'group'"
                    class="md-size-2x subscriber-lock"
                    :title="computedSubscribeText"
                    >lock</md-icon
                >

                <router-link
                    v-if="behavior === 'disallow' && item.type !== 'group'"
                    :title="computedSubscribeText"
                    to="/subscribe"
                    class="overlay"
                >
                    <div class="playbutton">
                        <md-icon class="md-size-2x subscriber-lock-button"
                            >lock</md-icon
                        >
                    </div>
                </router-link>
                <router-link
                    v-else
                    class="overlay"
                    :to="link"
                    :title="
                        item.type === 'video'
                            ? 'Watch Now'
                            : item.type === 'audio'
                              ? 'Listen'
                              : 'Explore'
                    "
                >
                    <div class="playbutton">
                        <svgicon
                            v-if="item.type !== 'group'"
                            name="gideo_play"
                            width="4rem"
                            height="4rem"
                            color="#ffffff"
                        ></svgicon>
                        <svgicon
                            v-else
                            name="arrow_forward"
                            width="4rem"
                            height="4rem"
                            color="#ffffff"
                        ></svgicon>
                    </div>
                </router-link>
            </div>
            <h3
                v-if="item.displayPosterTitle"
                class="title"
                :title="item.title"
            >
                {{ item.title }}
            </h3>
            <div class="description">
                <h4 v-if="item.displayPosterTitle" class="sub-title">
                    {{ item.subtitle || '&nbsp;' }}
                </h4>
                <div v-if="item.type !== 'group'" class="video-info">
                    <h6 v-if="showViews" class="video-views video-info-item">
                        {{ formattedViews }} views
                    </h6>
                    <span v-if="showViews && showDate" class="dot">•</span>
                    <h6 v-if="showDate" class="video-date video-info-item">
                        {{ formattedDate }}
                    </h6>
                    <span
                        v-if="
                            showViews &&
                            showDate &&
                            !item.scheduleId &&
                            !item.live
                        "
                        class="dot"
                        >•</span
                    >
                    <h6
                        v-if="!item.scheduleId && !item.live"
                        class="time video-info-item"
                    >
                        {{ formattedTime }}
                    </h6>
                </div>
                <!-- sanitized in xml parser -->
                <!-- eslint-disable-next-line -->
                <p v-html="item.webDescription" ></p>
                <router-link
                    class="playbutton small-btn"
                    :to="link"
                    title="Go to Details"
                >
                    <svgicon
                        name="arrow_forward"
                        width="3rem"
                        height="3rem"
                        color="#ffffff"
                    ></svgicon>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment-duration-format';
import '@/icons/gideo_play';
import '@/icons/arrow_forward';

export default {
    name: 'ProgramItemPoster',
    props: {
        item: {
            type: Object,
            required: true,
        },
        showDate: {
            type: Boolean,
            default: false,
        },
        showViews: {
            type: Boolean,
            default: false,
        },
        subscribeText: {
            type: String,
            default: 'Subscribe To Watch',
        },
    },
    computed: {
        computedSubscribeText: function () {
            return this.item.type === 'audio'
                ? this.subscribeText.replace(/watch/i, 'Listen')
                : this.subscribeText;
        },
        behavior: function () {
            const behavior = this.$gideo.subscriber
                ? 'subscriberBehavior'
                : 'freeBehavior';
            return this.item[behavior];
        },
        formattedTime: function () {
            if (this.item.length > 60) {
                return moment
                    .duration(this.item.length, 'seconds')
                    .format('h[h] m[m]');
            }

            return moment.duration(this.item.length, 'seconds').format('s[s]');
        },
        formattedDate: function () {
            return moment(this.item.added).fromNow();
        },
        formattedViews: function () {
            let suffixes = ['', 'k', 'm', 'b', 't'];
            let suffixNum = Math.floor((('' + this.item.views).length - 1) / 3);
            let shortValue = parseFloat(
                (suffixNum != 0
                    ? this.item.views / Math.pow(1000, suffixNum)
                    : this.item.views
                ).toPrecision(2),
            );
            if (shortValue % 1 != 0) {
                shortValue = shortValue.toFixed(1);
            }
            if (isNaN(shortValue)) return '0';
            return shortValue + suffixes[suffixNum];
        },
        watchLink: function () {
            if (this.item.type !== 'group') {
                return {
                    name: 'program',
                    params: {
                        id: this.item.parentId,
                        vid: this.item.id,
                    },
                };
            }

            return '';
        },
        link: function () {
            if (this.item.type === 'group') {
                return {
                    name: 'program-group',
                    params: {
                        groupId: this.item.id,
                    },
                };
            } else {
                //type is video or audio
                return {
                    name: 'program',
                    params: {
                        groupId: this.item.parentId,
                        programId: this.item.id,
                    },
                };
            }
        },
    },
};
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '../assets/css/_variables.scss';
$font-size: 0.8rem;
$ratio169: math.div(900%, 16);
.program-item {
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    background: transparent;
    font-size: $font-size;
    padding: 0;
    z-index: 0;
    position: relative;
    transition: z-index 0.35s ease;
    transition-delay: 0.2s;

    padding-bottom: calc(#{$ratio169} + 3.4em);

    .subscriber-lock {
        color: rgba(#fff, 0.5) !important;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0.5rem;
        transition: opacity 0.35s 0.2s;
    }
    .subscriber-lock-button {
        color: #fff !important;
    }
    .program-wrap {
        position: relative;
    }

    .program-background {
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: calc(100% + 20px);
        box-shadow: none;
        transform-origin: top;
        transform: scaleY(0.8);
        transition:
            transform 0.35s 0.2s ease,
            opacity 0.35s 0.2s ease,
            visibility 0s 0.65s ease;
        opacity: 0;
        visibility: hidden;
        &::after {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            box-shadow: 0 24px 88px -8px #333;
            opacity: 0;
            transition: opacity 0.35s cubic-bezier(0.99, 0.17, 1, 0.74);
        }
    }

    p {
        font-size: 1.5em;
        overflow: hidden;
        line-height: 1.4em;
        margin: 0.5em 0;
        max-height: 4em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
    @media (min-width: 1280px) {
        font-size: 1rem;
    }

    .title {
        font-size: 2em;
        font-weight: 500;
        line-height: 1.1em;
        text-align: left;
        margin: 0.6em 0 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .sub-title {
        font-size: 1.6em;
        font-weight: 500;
        line-height: 1.1em;
        text-align: left;
        margin: 0.5em 0 0.5em;
    }
    .video-info {
        text-align: left;
    }
    .video-info-item {
        font-size: 1.6em;
        font-weight: 300;
        line-height: 1.1em;
        margin: 0;
        margin: 0 0.3em 0.3em 0.3em;
        opacity: 0.8;
        display: inline-block;
        &:first-child {
            margin-left: 0;
        }
    }
    .dot {
        font-size: 1.6em;
        line-height: 1.1em;
        opacity: 0.7;
    }

    .description {
        max-height: 0;
        visibility: hidden;
        overflow: hidden;
        transition:
            visibility 0s 0.65s ease,
            max-height 0s 0.65s ease;
        & > * {
            transition: opacity 0.35s 0.2s ease;
            opacity: 0;
        }
        .playbutton {
            float: right;
        }
    }

    .poster {
        width: 100%;
        height: 0;
        padding-bottom: math.div(900%, 16);
        background-size: cover;
        transform-origin: bottom;
        transition: transform 0.35s 0.2s ease;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.35s 0.2s;
            .playbutton {
                z-index: 1;
                align-self: center;
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                z-index: 0;
            }
        }
    }

    @media (min-width: 1280px) {
        .mobile-slide &,
        &:hover {
            z-index: 10;
            .poster {
                .overlay {
                    opacity: 1;
                }
                .subscriber-lock {
                    opacity: 0;
                }
            }
            .title {
                white-space: pre-line;
            }
            .program-background {
                transition:
                    transform 0.35s 0.2s ease,
                    opacity 0.35s 0.2s ease;
                transform: scale(1.2, 1);
                opacity: 1;
                visibility: visible;
                &::after {
                    opacity: 1;
                }
            }

            .description {
                max-height: 1000px;
                visibility: visible;
                overflow: visible;
                transition: none;
                > * {
                    opacity: 1;
                }
            }
            .poster {
                transform: scale(1.2);
                transition: transform 0.35s 0.2s ease;
            }
        }
    }
}
</style>
