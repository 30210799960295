/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gideo_play': {
    width: 16,
    height: 16,
    viewBox: '0 0 144 144',
    data: '<path pid="0" d="M32.18 141a16.12 16.12 0 01-16.12-16.12V20.38a16.12 16.12 0 0132.24 0v104.51A16.12 16.12 0 0132.18 141zM74.88 123.73a16.12 16.12 0 01-16.13-16.12V37.3a16.13 16.13 0 0132.25 0v70.31a16.12 16.12 0 01-16.12 16.12zM117.57 95.92a16.12 16.12 0 01-16.12-16.12V65.11a16.13 16.13 0 0132.25 0V79.8a16.13 16.13 0 01-16.13 16.12z" _fill="#fff"/>'
  }
})
