import { extend, setInteractionMode } from 'vee-validate';
import {
    between,
    max,
    min,
    email,
    double,
    required,
    max_value,
    min_value,
} from 'vee-validate/dist/rules';
import moment from 'moment';

setInteractionMode('eager');
extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('email', email);
extend('double', double);
extend('max', {
    ...max,
    message: '{_field_} must contain no more than {length} characters',
});
extend('min', {
    ...min,
    message: '{_field_} must contain at least {length} characters',
});
extend('max_value', {
    ...max_value,
    message: '{_field_} must be less than or equal to {max}',
});
extend('min_value', {
    ...min_value,
    message: '{_field_} must be greater than or equal to {min}',
});
extend('tel', {
    validate(value) {
        let regex =
            /^(\+\d{1,2}\s)?((\(\d{3}\))|(\d{3}))[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return regex.test(value);
    },
    message: '{_field_} must be a valid phone number',
});
extend('password', {
    validate(value) {
        //at least one capital and one lowercase letter
        //and one number or special character
        let regex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d'"\-_+=.,<>:;()[\]{}@$!%*?/&#^])[A-Za-z\d'"\-_+=.,<>:;()[\]{}@$!%*?/&#^]+$/;
        return regex.test(value);
    },
    message:
        '{_field_} must contain a capital and lowercase letter and at least one number or special character',
});
extend('confirm', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: '{_field_} does not match {target}',
});
extend('required', {
    ...required,
    message: '{_field_} is required',
});
extend('donateBetween', {
    ...between,
    validate(value, { min, max }) {
        let parsedValue = value;
        if (typeof value === 'string') {
            parsedValue = parseFloat(value.replace(',', ''));
        }
        return parsedValue >= min && parsedValue <= max;
    },
    message: (fieldName, params) => {
        let regex = /\B(?=(\d{3})+(?!\d))/g;
        let min = params.min.toString().replace(regex, ',');
        let max = params.max.toString().replace(regex, ',');
        return `${fieldName} must be between $${min}.00 and $${max}.00`;
    },
});
extend('date', {
    validate(value, params) {
        const m = moment(value, params.format, true);
        return m.isValid();
    },
    params: ['format'],
    message: (fieldName, params) => {
        return `${fieldName} must be a valid date formated as ${params.format}`;
    },
});
extend('datemin', {
    validate(value, params) {
        const m = moment(value, params.format);
        return m.isSameOrAfter(moment(params.min, 'YYYY-MM-DD', true));
    },
    params: ['min'],
    message: (fieldName, params) => {
        return `${fieldName} must be after ${params.min}`;
    },
});
extend('datemax', {
    validate(value, params) {
        const m = moment(value, params.format);
        return m.isSameOrBefore(moment(params.max, 'YYYY-MM-DD', true));
    },
    params: ['max'],
    message: (fieldName, params) => {
        return `${fieldName} must be before ${params.max}`;
    },
});
extend('future', {
    validate(value, params) {
        const m = moment(value, params.format);
        return m.isAfter(moment());
    },
    params: ['format'],
    message: (fieldName) => {
        return `${fieldName} must be a date in the future`;
    },
});
