<template>
    <div
        v-if="settingsLoaded"
        id="app"
        class="md-layout md-alignment-space-between-center"
        :class="[theme]"
        :style="{
            'background-image': settings.backgroundImage
                ? 'url(' + settings.backgroundImage + ')'
                : false,
        }"
        @click="closeMenu"
    >
        <div id="navbar">
            <a
                v-if="$route.meta.hasBackButton"
                class="back-button"
                title="Back"
                @click.prevent="goBack"
            >
                <div class="playbutton">
                    <svgicon
                        class="mirror"
                        name="arrow_forward"
                        width="4rem"
                        height="4rem"
                        color="#ffffff"
                    ></svgicon>
                </div>
                <span class="button-text">BACK</span>
            </a>
            <router-link
                id="logo"
                to="/"
                role="image"
                :style="{
                    'background-image': 'url(' + settings.logo + ')',
                }"
            />

            <button id="menu-toggle" class="nav-icon" @click="toggleMenu">
                <span />
                <span />
                <span />
                <span />
                <div class="label">MENU</div>
            </button>

            <div id="nav-container" @click="closeMenu">
                <nav id="nav">
                    <button id="search-toggle" @click="toggleSearch">
                        <md-icon class="md-size-2x">search</md-icon>
                        <div class="label">SEARCH</div>
                    </button>

                    <template v-for="(link, i) in settings.links">
                        <a
                            v-if="/^(http|mailto|tel)/i.test(link.url)"
                            :key="i"
                            :href="link.url"
                            :target="link.target || ''"
                            >{{ link.text }}</a
                        >
                        <router-link
                            v-else
                            :key="link.text + link.url"
                            :to="link.url"
                            >{{ link.text }}</router-link
                        >
                    </template>

                    <router-link
                        v-if="!authed && settings.hasSignin"
                        to="/signup"
                        class="signup-link"
                        >{{
                            (settings.navText &&
                                (settings.navText.signup ||
                                    settings.navText.subscribe)) ||
                            'Sign Up Today'
                        }}</router-link
                    >
                    <router-link
                        v-else-if="
                            authed && !subscribed && settings.hasSubscriptions
                        "
                        to="/subscribe"
                        class="subscribe-link"
                        >{{
                            (settings.navText && settings.navText.subscribe) ||
                            'Subscribe Today'
                        }}</router-link
                    >
                    <router-link v-if="authed" to="/my-account"
                        >My Account</router-link
                    >

                    <a
                        v-if="authed && settings.hasSignin"
                        class="login-link"
                        href="#"
                        @click.prevent="logout()"
                        >Logout</a
                    >
                    <router-link
                        v-else-if="settings.hasSignin"
                        to="/login"
                        class="login-link"
                        >Login</router-link
                    >
                </nav>
            </div>
        </div>

        <cta-popup
            v-if="settings.cta && !authed && !$route.meta.noCta"
            :cta="settings.cta"
        />

        <donate-popup v-if="settings.allowDonations" :open.sync="showDonate" />
        <change-payment-method-dialog
            v-if="settings.hasIAP"
            :open.sync="showUpdatePaymentMethod"
            :message="paymentMethodMessage"
            @saved="showCardUpdatedNotification"
        />

        <search-overlay :open.sync="searchOpen" />

        <main>
            <transition name="fade">
                <router-view />
            </transition>
        </main>

        <footer id="app-footer">
            <div class="upper-footer">
                <div class="footer-info">
                    <span class="footer-info-item copyright">
                        <span v-if="!settings.hideCopyright"> &copy; </span>
                        {{ settings.company || settings.title }}
                        <span v-if="!settings.hideCopyright">
                            <span v-if="settings.copyStartYear">
                                {{ settings.copyStartYear }}-{{
                                    new Date().getFullYear()
                                }}.
                            </span>
                            <span v-else>
                                {{ new Date().getFullYear() }}
                            </span>
                            <!-- eslint-disable-next-line -->
                            <span v-if="settings.copyExtra" v-html="settings.copyExtra" />
                        </span>
                    </span>
                    <router-link
                        v-if="settings.hasTermsOfUse"
                        class="footer-info-item"
                        to="/terms-of-use"
                        >Terms of Use</router-link
                    >
                    <router-link
                        v-if="settings.hasPrivacyPolicy"
                        class="footer-info-item"
                        to="/privacy-policy"
                        >Privacy Policy</router-link
                    >
                    <router-link
                        v-if="settings.hasGift"
                        class="footer-info-item"
                        to="/gift-subscription"
                        >{{
                            (settings.navText && settings.navText.gift) ||
                            'Gift ' + settings.title
                        }}</router-link
                    >
                    <template v-for="(link, i) in settings.footerLinks">
                        <a
                            v-if="/^(http|mailto|tel)/i.test(link.url)"
                            :key="i"
                            class="footer-info-item"
                            :href="link.url"
                            :target="link.target || ''"
                            >{{ link.text }}</a
                        >
                        <router-link
                            v-else
                            :key="link.text + link.url"
                            class="footer-info-item"
                            :to="link.url"
                            >{{ link.text }}</router-link
                        >
                    </template>
                </div>
                <div class="social-icons">
                    <a
                        v-for="(social, i) in settings.social"
                        :key="i"
                        :href="social.url"
                    >
                        <fa-icon size="2x" :icon="['fab', social.name]" />
                    </a>
                </div>
            </div>
            <div class="lower-footer">
                <template v-if="settings.whiteLabelLogo">
                    <a
                        v-for="(logo, i) in settings.whiteLabelLogo"
                        :key="i"
                        :href="logo.url || '#'"
                        ><img :src="logo.image"
                    /></a>
                </template>
            </div>
        </footer>
        <md-snackbar
            :class="[notification.type]"
            md-position="center"
            :md-duration="10000"
            :md-active.sync="notification.active"
        >
            <span>{{ notification.message }}</span>
        </md-snackbar>
    </div>
</template>

<script>
import SearchOverlay from '@/components/SearchOverlay.vue';
const CtaPopup = () =>
    import(/* webpackChunkName: "cta-popup" */ '@/components/CTAPopup.vue');
const DonatePopup = () =>
    import(
        /* webpackChunkName: "donate-form" */ '@/components/DonatePopup.vue'
    );
const ChangePaymentMethodDialog = () =>
    import(
        /* webpackChunkName: "payment-method-form" */ '@/components/ChangePaymentMethodDialog.vue'
    );

export default {
    name: 'App',
    components: {
        SearchOverlay,
        CtaPopup,
        DonatePopup,
        ChangePaymentMethodDialog,
    },
    data: () => ({
        authed: false,
        subscribed: false,
        searchOpen: false,
        settings: {},
        settingsLoaded: false,
        showDonate: false,
        showUpdatePaymentMethod: false,
        paymentMethodMessage: '',
        notification: {
            message: '',
            active: false,
            type: '',
        },
        theme: 'body--dark',
    }),
    async created() {
        this.settings = await this.$appSettings;
        this.settingsLoaded = true;
        const title = this.settings.title;
        const cssURL = this.settings.cssURL;
        if (this.settings.theme) {
            this.theme = `body--${this.settings.theme}`;
        }
        this.authed = this.$gideo.authed;
        this.subscribed = this.$gideo.subscriber;

        const showUpdatePaymentMethod = (e) => {
            this.paymentMethodMessage = e?.message || '';
            this.showUpdatePaymentMethod = true;
        };
        this.$gideo.bus.$on('payment-method-open', showUpdatePaymentMethod);

        const setAuthed = () => {
            this.authed = this.$gideo.authed;
            this.subscribed = this.$gideo.subscriber;
            if (this.authed) {
                this.checkUserPaymentMethod();
            }
        };
        this.$gideo.bus.$on('logged-in', setAuthed);
        this.$gideo.bus.$on('logged-out', setAuthed);
        this.$gideo.bus.$on('subscribed', setAuthed);

        const showDonate = () => {
            this.showDonate = true;
        };
        this.$gideo.bus.$on('donate-open', showDonate);

        //add the site title and favicon
        let head = document.getElementsByTagName('head')[0];
        let titleEl = document.createElement('title');
        let favicon = document.createElement('link');
        favicon.setAttribute('rel', 'icon');
        favicon.setAttribute('href', `${this.settings.assetsURL}favicon.ico`);
        titleEl.append(title);

        head.append(titleEl);
        head.append(favicon);

        if (cssURL) {
            let css = document.createElement('link');
            css.setAttribute('rel', 'stylesheet');
            css.setAttribute('href', cssURL);
            head.append(css);
        }

        this.$router.beforeEach((to, from, next) => {
            //close the menu on navigation
            document.body.classList.remove('nav-open');
            this.searchOpen = false;
            next();
        });

        this.checkUserPaymentMethod();
    },
    mounted() {
        this.checkUserPaymentMethod();
    },
    methods: {
        logout() {
            this.$gideo.logout();
            this.$router.push('/');
        },
        async checkUserPaymentMethod() {
            const user = await this.$gideo.getUserInfo();
            if (user?.paymentMethodMessage) {
                this.$gideo.bus.$emit('payment-method-open', {
                    message: user.paymentMethodMessage,
                });
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        toggleMenu() {
            document.body.classList.toggle('nav-open');
        },
        toggleSearch() {
            this.searchOpen = !this.searchOpen;
            if (this.searchOpen) {
                document.body.classList.remove('nav-open');
            }
        },
        closeMenu(e) {
            const nav = document.getElementById('nav');
            const menuButton = document.getElementById('menu-toggle');
            if (
                e.target != nav &&
                !nav.contains(e.target) &&
                e.target != menuButton &&
                !menuButton.contains(e.target)
            ) {
                document.body.classList.remove('nav-open');
            }
        },
        showCardUpdatedNotification() {
            this.notification.message = `Payment Method Successfully Updated!`;
            this.notification.active = true;
            this.notification.type = 'note-success';
        },
    },
};
</script>
<style lang="scss">
@use 'sass:math';
@import '@DEV_CSS';
@import './assets/css/_app.scss';

html {
    font-size: 10px !important;
    @media (min-width: 320px) {
        font-size: math.div(1000vw, 320) !important;
    }
    @media (min-width: 600px) {
        font-size: math.div(1000vw, 600) !important;
    }
    @media (min-width: 1280px) {
        font-size: math.div(1000vw, 1280) !important;
    }
    background-color: $background-color;
}
body {
    margin: 0;
    padding: 0;
    font-size: 1rem !important;
    &.nav-open,
    &.search-open {
        overflow: hidden;
    }
    .md-dialog-overlay {
        z-index: 110;
    }
    .md-dialog {
        z-index: 120;
    }
}

*,
::after,
::before {
    box-sizing: border-box;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
    background-color: $background-color;
    min-height: 100vh;
    width: 100%;
    padding-top: $navHeight;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    flex-direction: column;
}

.captcha-terms {
    text-align: center;
    width: 100%;
}

main {
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

#search-toggle {
    background: transparent;
    border: none;
    padding: 0;
    color: #fff;
    text-align: left;
    z-index: 0;
    transition: z-index linear;
    transition-delay: 0.4s;
    width: 100%;
    padding: 0 math.div(2em, 1.4);
    font-weight: 400;
    font-size: 1.4rem;
    $borderColor: scale-color($navColor, $lightness: 10%);
    border-bottom: $borderColor 1px solid;
    border-top: $borderColor 1px solid;

    @media (min-width: 1280px) {
        border: none;
        cursor: pointer;
        width: auto;
        height: 100%;
        transition: opacity ease 0.4s;
        &:hover,
        &:active {
            opacity: 0.5;
        }
    }

    .search-open & {
        visibility: hidden;
    }

    .md-icon {
        color: #fff;
        width: auto;
        height: 3.8rem;
        line-height: 6rem;
        display: inline;
        font-size: 3.8rem !important;
    }
    .label {
        line-height: 6rem;
        text-align: center;
        display: inline;

        @media (min-width: 1280px) {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1em;
            width: 100%;
        }
    }

    .body--light & {
        border-color: scale-color($background-color-light, $lightness: -10%);
        color: $body-color-light;

        .md-icon {
            color: $body-color-light;
        }
    }
}
.body--light {
    .nav-icon {
        .label {
            color: $body-color-light;
        }
        span {
            background-color: $body-color-light;
        }
    }
}
#navbar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: $navHeight;
    padding: 0 3.5rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: $navColor;

    .body--light & {
        background-color: $background-color-light;
        border-bottom: scale-color($background-color-light, $lightness: -10%)
            1px solid;

        .back-button {
            .playbutton {
                border-color: $body-color-light;
                svg {
                    path {
                        fill: $body-color-light;
                    }
                }
            }
            .button-text {
                color: $body-color-light;
            }
        }
    }

    @media (min-width: 1280px) {
        justify-content: space-between;
    }

    .back-button {
        cursor: pointer;
        text-decoration: none;
        position: absolute;
        color: inherit;
        z-index: 110;
        top: 1rem;
        left: 2rem;

        @media (min-width: 1280px) {
            display: none;
        }
        svg {
            transform: rotate(180deg);
        }
        .button-text {
            display: block;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1em;
            margin-top: 0.5em;
        }
        .playbutton {
            transition: ease 0.3s;
        }
        &:hover {
            .playbutton {
                transform: translate(-5%);
            }
        }
    }

    a#logo {
        display: block;
        flex: 0 1 auto;
        margin: 1.6rem 0;
        width: 12rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        @media (min-width: 1280px) {
            width: 20rem;
            background-position: left center;
        }
    }

    #nav-container {
        z-index: -1;
        height: calc(100vh - #{$navHeight});
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: $navHeight;
        left: 0;
        transition: z-index ease 0.5s;
        &::after {
            transition: opacity ease 0.2s;
            opacity: 0;
            content: '';
        }
        body.nav-open & {
            z-index: 100;
            &::after {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #000;
                opacity: 0.7;
            }
        }
        @media (min-width: 1280px) {
            position: relative;
            top: 0;
            height: 100%;
            z-index: 100;
            &::after {
                display: none;
            }
        }
    }

    #nav {
        position: absolute;
        overflow: hidden;
        height: 0;
        top: 0;
        right: 0;
        z-index: 1000;
        background-color: $navColor;
        width: 100%;
        transition: height ease 0.3s;

        body.nav-open & {
            height: calc(100vh - #{$navHeight});
        }

        @media (min-width: 600px) {
            width: 40%;
            right: -40%;
            height: 100%;
            transition: right ease 0.3s;
            body.nav-open & {
                right: 0;
            }
        }

        @media (min-width: 1280px) {
            position: relative;
            left: 0;
            width: auto;
            display: flex;
            justify-content: flex-end;

            body.nav-open & {
                height: 100%;
            }
        }

        a {
            display: inline-block;
            $borderColor: scale-color($navColor, $lightness: 10%);
            border-bottom: $borderColor 1px solid;
            width: 100%;
            line-height: 6rem;
            text-align: left;
            text-decoration: none;
            text-transform: uppercase;
            padding: 0 math.div(2em, 1.4);
            font-weight: 400;
            font-size: 1.4rem;
            color: rgba(#fff, 0.9);
            transition: opacity ease 0.4s;
            opacity: 0;

            &:first-child {
                border-top: $borderColor 1px solid;
            }
            &:last-child {
                border-bottom: $borderColor 1px solid;
            }

            body.nav-open & {
                opacity: 1;
            }

            &.router-link-exact-active {
                color: #448aff;
            }

            @media (min-width: 1280px) {
                opacity: 1;
                padding: 0 1.2em;
                text-align: center;
                height: 100%;
                line-height: $navHeight;
                width: auto;
                border: none;

                &:last-child {
                    border: none;
                }

                &:hover,
                &:active {
                    opacity: 0.5;
                }
            }
        }
        .login-link {
            font-weight: 700;
        }
    }

    .body--light & {
        #nav {
            background-color: $background-color-light;
            $nav-border-color: scale-color(
                $background-color-light,
                $lightness: -10%
            );
            a {
                color: $body-color-light;
                border-bottom: $nav-border-color 1px solid;

                &:first-child {
                    border-top: $nav-border-color 1px solid;
                    @media (min-width: 1280px) {
                        border: none;
                    }
                }
                &:last-child {
                    border-bottom: $nav-border-color 1px solid;
                    @media (min-width: 1280px) {
                        border: none;
                    }
                }

                @media (min-width: 1280px) {
                    border: none;
                    &:hover,
                    &:active {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

#app-footer {
    $fbc: scale-color($background-color, $lightness: 6%);
    background-color: $fbc;
    padding: 1.5rem;
    position: relative;

    @media (min-width: 1280px) {
        height: 5rem;
    }

    .upper-footer {
        margin-bottom: 0.5rem;

        @media (min-width: 1280px) {
            overflow: hidden;
            text-align: left;
            margin: 0;
            position: relative;
        }

        .footer-info-item {
            font-size: 1rem;
            margin-right: 0.5em;
            display: inline-block;
            &:last-child {
                margin: 0;
            }
            &:not(:last-child):not(.copyright)::after {
                content: '|';
                margin-left: 0.5em;
            }
        }

        .footer-info {
            margin-bottom: 0.5rem;
            @media (min-width: 1280px) {
                float: left;
                margin: 0;
            }
        }

        .social-icons {
            @media (min-width: 1280px) {
                float: right;
            }
            a {
                color: #fff;
                margin-right: 0.6rem;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .lower-footer {
        @media (min-width: 1280px) {
            position: absolute;
            margin: auto;
            transform: translateX(-50%);
            height: 100%;
            left: 50%;
            top: 0;
            padding: 0.5rem 1.5rem;
        }
        a {
            margin: auto;
            max-width: 15rem;
            display: inline-block;
            height: 4em;
            padding: 0 0.3em;
            @media (min-width: 1280px) {
                height: 100%;
            }
        }
        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
    width: 100%;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

div.md-snackbar.note-error {
    background: scale-color(#ff1744, $lightness: 90%);
    color: #ff1744;
}
div.md-snackbar.note-success {
    background: scale-color(#229f22, $lightness: 90%);
    color: #229f22;
}
.md-success {
    &:not(.md-empty-state) {
        background-color: #229f22 !important;
        color: #fff !important;
    }
    &.md-empty-state {
        .md-icon {
            color: #229f22 !important;
        }
    }
}
.md-error {
    &:not(.md-empty-state):not([disabled]):not(.md-stepper-header) {
        background-color: #ff1744 !important;
        color: #fff !important;
    }

    &.md-empty-state {
        .md-icon {
            color: #ff1744 !important;
        }
    }
}
</style>
