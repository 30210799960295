<template>
    <div :class="{ 'search-overlay': true, open: open }">
        <div class="search-input-container">
            <button class="search-close" @click="closeSearch">
                <md-icon class="md-size-2x">close</md-icon>
            </button>
            <input
                v-model="searchTerm"
                class="search-input"
                type="text"
                placeholder="Type to Search..."
            />
        </div>

        <div v-if="showNoResults" class="no-results">
            <md-icon class="md-size-3x">search</md-icon>
            <h6>No Results Found</h6>
        </div>

        <div
            v-if="loading"
            class="spinner md-layout md-alignment-center-center"
        >
            <md-progress-spinner md-mode="indeterminate" />
        </div>

        <div v-if="results && results.length" class="results-scroller">
            <program-group-grid :items="results" />
        </div>
    </div>
</template>

<script>
import ProgramGroupGrid from '@/components/ProgramGroupGrid.vue';
import _debounce from 'lodash/debounce';

export default {
    name: 'SearchOverlay',
    components: {
        ProgramGroupGrid,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        results: [],
        searchTerm: '',
        loading: false,
        typing: false,
    }),
    computed: {
        showNoResults: function () {
            if (
                (this.results && this.results.length > 0) ||
                !this.searchTerm ||
                this.loading ||
                this.typing
            ) {
                return false;
            }

            return true;
        },
    },
    watch: {
        open: function (nowOpen) {
            document.body.classList.toggle('search-open');
            if (nowOpen) {
                // wait until css animation finishes
                setTimeout(() => {
                    this.$el.querySelector('.search-input').focus();
                }, 500);
            }
        },
        searchTerm: function () {
            this.typing = true;
            this.debouncedSearch();
        },
    },
    created: function () {
        this.debouncedSearch = _debounce(this.search, 500);
    },
    methods: {
        closeSearch() {
            this.$emit('update:open', false);
        },
        search() {
            this.loading = true;
            this.typing = false;
            this.results = [];
            this.$gideo
                .search(this.searchTerm)
                .then((res) => {
                    this.results = res.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import '../assets/css/_variables.scss';

.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    z-index: 1100;
    opacity: 0;
    overflow: hidden;
    background-color: rgba($background-color, 0.96);
    transition:
        height 0.3s ease,
        opacity 0.3s ease;
    text-align: left;

    &.open {
        height: 100%;
        opacity: 1;
    }

    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .no-results {
        text-align: center;
        h6 {
            margin: 1rem;
            font-size: 2.5em;
        }
        .md-icon {
            color: #fff;
            width: 7.2rem;
            height: 7.2rem;
            font-size: 7.2rem !important;
        }
    }

    .results-scroller {
        overflow: auto;
        width: 100%;
        height: calc(100% - 10rem);
    }

    .search-close {
        padding: 0;
        background: transparent;
        border: none;
        width: 3.8rem;
        height: 3.8rem;
        transition:
            transform 0.3s ease 0.2s,
            opacity 0.3s ease 0.2s;
        transform: scale(0);
        cursor: pointer;

        .search-open & {
            opacity: 0.8;
            transform: scale(1);
        }
        .md-icon {
            color: #fff;
            width: 3.8rem;
            height: 3.8rem;
            min-width: 3.8rem;
            font-size: 3.8rem !important;
        }
    }
    .search-input {
        background: transparent;
        border: none;
        height: 100%;
        color: #fff;
        padding: 0 0.5em;
        margin-left: 1.5rem;
        font-size: 2rem;
        width: calc(100% - 5.3rem);
        outline: none;
        opacity: 0;
        transition:
            transform 0.3s ease 0.2s,
            opacity 0.3s ease 0.2s;
        transform: translateX(100%);

        .search-open & {
            opacity: 0.8;
            transform: translateX(0);
        }
        @media (min-width: 600px) {
            transform: translateX(25%);
            transition:
                transform 0.3s ease,
                opacity 0.3s ease;
        }
    }

    .search-input-container {
        position: relative;
        display: inline-block;
        height: 4rem;
        width: 100%;
        padding: 0 1rem;
        left: 0;
        margin: 2rem 0 4rem;

        &::after {
            content: '';
            position: absolute;
            left: 0.5rem;
            bottom: -1rem;
            width: calc(100% - 1rem);
            height: 0.2rem;
            border-radius: 50%;
            background-color: rgba(#eee, 0.5);
            transform: scaleX(0);
            transition: transform 0.3s ease 0.2s;
            transform-origin: 0 0;

            .search-open & {
                transform: scaleX(1);
            }
        }
        &::before {
            content: '';
            position: absolute;
            left: 5.8rem;
            top: -0.5rem;
            height: calc(100% + 1rem);
            width: 0.2rem;
            border-radius: 50%;
            background-color: rgba(#eee, 0.5);
            transform: scaleY(0);
            transition: transform 0.3s ease 0.2s;

            .search-open & {
                transform: scaleY(1);
            }
        }
    }
}
</style>
