import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import gideo from '@/services/gideo.js';
import SvgIcon from 'vue-svgicon';
import VueMq from 'vue-mq';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import {
    MdToolbar,
    MdSnackbar,
    MdDivider,
    MdField,
    MdMenu,
    MdRadio,
    MdList,
    MdCheckbox,
    MdButton,
    MdCard,
    MdProgress,
    MdDialog,
    MdDialogConfirm,
    MdSwitch,
    MdEmptyState,
    MdSteppers,
} from 'vue-material/dist/components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAmazon,
    faFacebook,
    faInstagram,
    faTwitter,
    faSpotify,
    faYoutube,
    faApple,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faAmazon,
    faFacebook,
    faInstagram,
    faTwitter,
    faSpotify,
    faYoutube,
    faApple,
    faLinkedin,
);

Vue.component('FaIcon', FontAwesomeIcon);

require('@/services/validation.js');

Vue.config.productionTip = false;

Vue.use(gideo);
Vue.use(VueReCaptcha, {
    siteKey: '6LctOXcoAAAAALbwU3sNjBC8tOciXYHXQhJYfzZ4',
    loaderOptions: {
        useEnterprise: true,
        autoHideBadge: true,
    },
});
Vue.use(SvgIcon);
Vue.use(VueMq, {
    breakpoints: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1920,
        xl: Infinity,
    },
});

Vue.use(MdToolbar);
Vue.use(MdSnackbar);
Vue.use(MdDivider);
Vue.use(MdField);
Vue.use(MdRadio);
Vue.use(MdList);
Vue.use(MdMenu);
Vue.use(MdCheckbox);
Vue.use(MdButton);
Vue.use(MdCard);
Vue.use(MdProgress);
Vue.use(MdDialog);
Vue.use(MdDialogConfirm);
Vue.use(MdSwitch);
Vue.use(MdEmptyState);
Vue.use(MdSteppers);
Vue.component(
    'MdSelect',
    Vue.options.components.MdSelect.extend({
        methods: {
            isInvalidValue: function isInvalidValue() {
                return this.$el.validity
                    ? this.$el.validity.badInput
                    : this.$el.querySelector('input').validity.badInput;
            },
        },
    }),
);
new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
