const segmentHandlers = {
    GAITHER: {
        onAuthenticate: (user) => {
            if (typeof window?.analytics?.identify !== 'function') {
                //eslint-disable-next-line
                console.warn('segment.identify not available. please include it in the head');
                return;
            }

            window.analytics.identify(user.id, {
                created: user.signupDate,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
            });
        },
        onPurchase: (user, product) => {
            if (typeof window?.analytics?.track !== 'function') {
                //eslint-disable-next-line
                console.warn('segment.track not available. please include it in the head');
                return;
            }

            window.analytics.track('Order Completed', {
                type: 'platform',
                device: 'web',
                user_id: user.id,
                user_email: user.email,
                title: 'Gaither TV – Checkout Page',
                url: window.location.href,
                timestamp: new Date().toISOString(),
                plan_type: product.duration === 8760 ? 'yearly' : 'monthly',
                revenue: parseFloat((product.price / 100).toFixed(2)),
                referrer: document.referrer,
                view: 'checkout',
            });
        },
    },
};

async function getSegmentHandlers(accountId) {
    return segmentHandlers[accountId];
}

module.exports = {
    getSegmentHandlers,
};
