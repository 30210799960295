import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

function infoPropsFunction(route) {
    const gideo = Vue.prototype.$gideo;
    return {
        info: gideo[route.meta.loadFunction]().then((info) => {
            return info.data;
        }),
    };
}

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        props: (route) => Object.assign({}, route.query, route.params),
    },
    {
        path: '/index.html',
        redirect: '/',
    },
    {
        path: '/',
        name: 'home',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
        path: '/gift-subscription',
        name: 'giftSubscription',
        component: () =>
            import(
                /* webpackChunkName: "gift-subscription" */ '../views/GiftSubscription.vue'
            ),
        meta: { hasBackButton: true, noCta: true },
        beforeEnter: async (to, from, next) => {
            const settings = await Vue.prototype.$appSettings;
            if (!settings.hasSubscriptions || !settings.hasGift) {
                next(false);
            } else {
                next();
            }
        },
    },
    {
        path: '/redeem/:code',
        name: 'giftRedeem',
        component: () =>
            import(
                /* webpackChunkName: "gift-redeem" */ '../views/RedeemGiftSubscription.vue'
            ),
        props: true,
        meta: { hasBackButton: true, noCta: true },
        beforeEnter: async (to, from, next) => {
            const settings = await Vue.prototype.$appSettings;
            if (!settings.hasSubscriptions || !settings.hasGift) {
                next(false);
            } else {
                next();
            }
        },
    },
    {
        path: '/delete-my-data',
        name: 'deleteUserAccount',
        component: () =>
            import(
                /* webpackChunkName: "signup" */ '../views/DeleteUserAccount.vue'
            ),
        meta: { hasBackButton: false, noCta: true },
        beforeEnter: async (to, from, next) => {
            const settings = await Vue.prototype.$appSettings;
            if (settings.hasSignin) {
                next();
            } else {
                next(false);
            }
        },
    },
    {
        path: '/signup',
        name: 'signup',
        component: () =>
            import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
        props: (route) => ({
            redirect: route.query?.redirect || '/',
            subscription: route.query?.subscription,
        }),
        meta: { hasBackButton: true, noCta: true },
        beforeEnter: async (to, from, next) => {
            const gideo = Vue.prototype.$gideo;
            const settings = await Vue.prototype.$appSettings;
            const redirect =
                to.query?.redirect ||
                (from.fullPath && from.fullPath !== '/'
                    ? from.fullPath
                    : undefined);
            if (gideo.authed && settings.hasSubscriptions) {
                next({
                    name: 'subscribe',
                    query: Object.assign({}, to.query, {
                        redirect,
                        subscription: to.query?.subscription,
                    }),
                });
            } else if (settings.hasSignin) {
                if (!to.query?.redirect && redirect) {
                    next({
                        name: 'signup',
                        query: Object.assign({}, to.query, {
                            redirect,
                            subscription: to.query?.subscription,
                        }),
                    });
                } else {
                    next();
                }
            } else {
                next(false);
            }
        },
    },
    {
        path: '/subscribe',
        name: 'subscribe',
        component: () =>
            import(/* webpackChunkName: "signup" */ '../views/Subscribe.vue'),
        props: (route) => ({
            redirect: route.query?.redirect || '/',
            subscription: route.query?.subscription,
        }),
        meta: { hasBackButton: true, noCta: true },
        beforeEnter: async (to, from, next) => {
            const gideo = Vue.prototype.$gideo;
            const settings = await Vue.prototype.$appSettings;
            const redirect =
                to.query?.redirect ||
                from.query?.redirect ||
                (from.fullPath && from.fullPath !== '/'
                    ? from.fullPath
                    : undefined);
            if (!gideo.authed && settings.hasSignin) {
                next({
                    name: 'signup',
                    query: Object.assign({}, to.query, {
                        redirect: redirect,
                        subscription: to.query?.subscription,
                    }),
                });
            } else if (settings.hasSubscriptions) {
                if (!to.query?.redirect && redirect) {
                    next({
                        name: 'subscribe',
                        query: Object.assign({}, to.query, {
                            redirect: redirect,
                            subscription:
                                to.query?.subscription ||
                                from.query?.subscription,
                        }),
                    });
                } else {
                    next();
                }
            } else {
                next(false);
            }
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacyPolicy',
        component: () =>
            import(/* webpackChunkName: "info" */ '../views/Info.vue'),
        props: infoPropsFunction,
        meta: { loadFunction: 'getPrivacyPolicy' },
    },
    {
        path: '/terms-of-use',
        name: 'termsOfUse',
        component: () =>
            import(/* webpackChunkName: "info" */ '../views/Info.vue'),
        props: infoPropsFunction,
        meta: { loadFunction: 'getTermsOfUse' },
    },
    {
        path: '/my-account',
        name: 'myaccount',
        component: () =>
            import(/* webpackChunkName: "account" */ '../views/Account.vue'),
        meta: { hasBackButton: true },
        beforeEnter: async (to, from, next) => {
            const gideo = Vue.prototype.$gideo;
            if (!gideo.authed) {
                next({ name: 'login' });
            } else {
                next();
            }
        },
    },
    //    {
    //path: '/about',
    //name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () =>
    //    import(/* webpackChunkName: "about" */ '../views/About.vue')
    //    },
    {
        path: '/program-group/:groupId*/program/:programId',
        name: 'program',
        component: () =>
            import(/* webpackChunkName: "details" */ '../views/Details.vue'),
        props: true,
        meta: { hasBackButton: true },
    },
    {
        path: '/program-group/:groupId*',
        name: 'program-group',
        component: () =>
            import(
                /* webpackChunkName: "program-group" */ '../views/ProgramGroup.vue'
            ),
        props: true,
        meta: { hasBackButton: true },
    },
    {
        path: '/page/:pageId',
        name: 'customPage',
        component: () =>
            import(
                /* webpackChunkName: "custom-page" */ '../views/CustomPage.vue'
            ),
        props: true,
    },
    {
        path: '/user/:userId/change-password/:token',
        name: 'changePassword',
        component: () =>
            import(
                /* webpackChunkName: "change-password" */ '../views/ChangePassword.vue'
            ),
        props: true,
    },
    {
        path: '/roku',
        name: 'RokuLinkMessage',
        component: () =>
            import(
                /* webpackChunkName: "roku-link-message" */ '../views/RokuLinkMessage.vue'
            ),
    },
    {
        path: '/*',
        name: 'PageNotFound',
        component: () =>
            import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

export default router;
