<template>
    <div v-if="programItems && programItems.length" class="program-grid">
        <h3 class="program-title">{{ title }}</h3>
        <div class="md-layout md-gutter">
            <div
                v-for="item in programItems"
                :key="item.id"
                class="program-grid-item md-layout-item md-size-25 md-xsmall-size-100 md-small-size-50 md-medium-size-33"
            >
                <ProgramItemPoster
                    :item="item"
                    :show-date="showVideoDate"
                    :show-views="showVideoViews"
                    :subscribe-text="subscribeText"
                ></ProgramItemPoster>
            </div>
        </div>
    </div>
</template>

<script>
import ProgramItemPoster from '@/components/ProgramItemPoster.vue';

export default {
    name: 'ProgramGroupGrid',
    components: {
        ProgramItemPoster,
    },
    props: {
        groupId: {
            type: String,
            default: undefined,
        },
        hasUserSuggestions: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        groupItems: [],
        showVideoDate: false,
        showVideoViews: false,
        subscribeText: 'Subscribe To Watch',
    }),
    computed: {
        programItems: function () {
            if (!this.groupId && this.items.length > 0) {
                return this.items;
            } else {
                return this.groupItems;
            }
        },
    },
    watch: {
        groupId() {
            this.updateContents();
        },
    },
    async created() {
        const settings = await this.$appSettings;
        this.showVideoDate = !!settings.showVideoDate;
        this.showVideoViews = !!settings.showVideoViews;
        this.subscribeText = settings.hasSubscriptions
            ? settings.signupButtonText || 'Subscribe To Watch'
            : settings.signupButtonText || 'Signup To Watch';

        this.updateContents();
    },
    methods: {
        async updateContents() {
            const groupId = this.groupId;
            if (groupId === undefined) {
                return;
            }

            const items = this.hasUserSuggestions
                ? await this.$gideo.getSuggestedContent(groupId)
                : await this.$gideo.getProgramGroups(groupId);
            if (groupId === this.groupId) {
                this.groupItems = items.data;
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import '../assets/css/_variables.scss';
.program-grid {
    font-size: 1.4rem;
    padding: 0 calc(2em + 2rem) 20rem;

    @media (min-width: 600px) {
        font-size: 2rem;
    }
}
.program-grid-item {
    margin-bottom: 3em;
}
</style>
