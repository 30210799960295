let webtvConfig = undefined;
if (process.env.NODE_ENV === 'development') {
    //gideo test key
    //const domain = 'temp.parfm.gideo.video';
    //const domain = 'calvaryeagles.tv';
    const domain = 'webtvtest.streamotor.com';
    const localAPI = true;
    /*
    const paymentProcessor = {
        brand: 'stripe',
        key: 'pk_test_AMvnTBd7fTC3esjG4j7NVAVK0053b8Yts8',
    };
    */
    global.webtvConfig = webtvConfig = {
        domain,
        //company: 'Gaither',
        //title: 'GaitherTV',
        copyExtra: '<br>All Rights Reserved.',
        copyStartYear: '2018',
        //hasSignin: true,
        //hasSubscriptions: true,
        //hasIAP: true,
        apiBaseUrls: localAPI
            ? {
                  ott: 'http://127.0.0.1:50180/api/legacy',
                  auth: 'http://127.0.0.1:50180/api/legacy',
                  store: 'http://127.0.0.1:50180/api/legacy',
              }
            : {
                  ott: 'https://ott.gideo.video/api/legacy',
                  auth: `https://ott.gideo.video/api/legacy`,
                  store: 'https://ott.gideo.video/api/legacy',
                  analytics: '',
              },
        settingsBaseURL: localAPI
            ? 'http://127.0.0.1:50180/api/legacy'
            : undefined,
        //paymentProcessor: { brand: 'ezic' },
        //paymentProcessor,
        //assetsURL: `http://${domain}/schemes/${scheme}/Main/webtv_assets/`,
        navText: {
            //signup: 'Sign Up Today'
            //subscribe: 'Subscribe Today',
            //gift: 'Give the Gift of Gaither'
        },
        //signupButtonText: 'Login or Signup to Watch for Free',
        privacyTermsValues: {
            //this could have any properties and is used by mustache to render the privacy policy and tos
        },
        giftThankYouText: 'Thank you for giving the gift of Gaither TV!',
        //hasGift: true,
        logo: `https://${domain}/assets/logo.png`,
        backgroundImage: `https://${domain}/assets/background.png`,
        //backgroundImage: true,
        //disqusShortname: 'watch-secureserver-tv',
        whiteLabelLogo: [
            {
                image: `https://${domain}/assets/logo.png`,
            },
            /*
            {
                image: `https://${domain}/assets/rokuBadgeLatam.png`,
            },
            /*
            {
                image: 'https://watch.canyonstar.tv/assets/roku.png',
            },
            {
                image: 'https://watch.canyonstar.tv/assets/appletv.png',
            },
            {
                image: 'https://watch.canyonstar.tv/assets/firetv.png',
            },
            {
                image: 'https://watch.canyonstar.tv/assets/android.png',
            },
            {
                image: 'https://watch.canyonstar.tv/assets/google.png',
            },
            {},
            //*/
        ],
        //cssURL: `http://${domain}/schemes/${scheme}/Main/webtv_assets/css/main.css`,
        theme: 'dark',
        links: [
            {
                text: 'Link Test',
                url: 'http://gideo.video',
                target: '_blank',
            },
        ],
        /*
        footerLinks: [
            {
                text: 'Link Test',
                url: 'http://gideo.video',
                target: '_blank',
            },
            {
                text: 'cookie policy',
                url: '/cookie-policy',
            },
        ],
        //*/
        //hideCopyright: true,
        social: [
            {
                name: 'facebook',
                url: 'https://www.facebook.com/Gaithermusic/',
            },
            { name: 'twitter', url: 'https://twitter.com/Gaithermusic' },
            {
                name: 'instagram',
                url: 'https://www.instagram.com/gaithermusic/',
            },
            {
                name: 'spotify',
                url: 'https://open.spotify.com/album/0VCpg23eyIrC9JnLvZxmUs?si=zbLw9bp7RUGkfFwmRLLJfw',
            },
            {
                name: 'youtube',
                url: 'http://smarturl.it/GaitherMusicTV_Subsc',
            },
            {
                name: 'apple',
                url: 'https://music.apple.com/us/artist/gaither-vocal-band/6526032',
            },
            {
                name: 'amazon',
                url: 'https://www.amazon.com/stores/page/D3CF6DAA-BA26-480A-B1B2-5FCE7589BDBD?channel=Gaither',
            },
            {
                name: 'linkedin',
                url: 'https://www.amazon.com/stores/page/D3CF6DAA-BA26-480A-B1B2-5FCE7589BDBD?channel=Gaither',
            },
        ],
    };
}
module.exports = webtvConfig;
