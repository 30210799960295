/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_forward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="1" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>'
  }
})
