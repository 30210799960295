const path = require('path');
let devPages = undefined;
if (process.env.NODE_ENV === 'development') {
    devPages = {};
    const context = require.context(
        './devAssets/customPages',
        false,
        /\.html$/,
    );
    context.keys().forEach((file) => {
        const filename = path.parse(file)?.name;
        devPages[filename] = context(file);
    });
}
module.exports = devPages;
